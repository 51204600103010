<template>
  <div class="h-screen">
    <div class="flex justify-between p-5">
      <h1 class="font-semibold text-xl text-white">Location</h1>
      <p>
        <a class="p-2 font-semibold bg-mindaro" id="popup-btn" href="#popup"
          >+ Create Location</a
        >
      </p>
    </div>
    <RegisterLocation @add-location="newLocation" />
    <div class="p-5">
      <h1 class="py-4 px-2 bg-brightGray text-darkBlue font-semibold">
        Register Location Admin
      </h1>
      <div class="bg-white p-2">
        <p>In order to be able to register admins, create the location.</p>
        <form @submit.prevent="handleSubmit">
          <div class="flex flex-col md:flex-row md:items-center mt-4">
            <div class="w-full md:w-1/4 md:mt-2">
              <input
                class="w-full p-2 bg-brightGray"
                type="email"
                name=""
                id=""
                placeholder="email"
                v-model="email"
                required
              />
            </div>
            <div class="mt-2 md:ml-3 select">
              <select
                v-model="locationChoosen"
                name="sport"
                placeholder="Choose an option"
                class="px-5 py-2 w-full"
              >
                <option value="null" selected disabled>Choose location</option>

                <option
                  v-for="location in locationss"
                  :key="location"
                  :value="location.name"
                >
                  {{ location.name }}
                </option>
              </select>
            </div>
            <button
              type="submit"
              class="bg-darkBlue px-5 py-2 text-white ml-3 mt-3"
            >
              Register Admin
            </button>
          </div>
        </form>
        <div class="italic" v-if="showMessage">
          <p>{{ message }}</p>
        </div>
      </div>
      <LocationsTable :data="locationss" />
    </div>
  </div>
</template>

<script setup>
import RegisterLocation from "@/components/location/RegisterLocation.vue";
import LocationsTable from "@/components/location/LocationsTable.vue";
import {
  locations,
  createLocationCode,
  registrationEmail,
} from "@/services/location/location.js";
import { ref, onMounted } from "vue";

const locationChoosen = ref(null);
const locationss = ref([]);
const email = ref(null);
const showMessage = ref(false);
const message = ref("An error has ocurred, please try again");

onMounted(async () => {
  const response = await locations();
  locationss.value = response.data.locations;
});

function newLocation(name) {
  locationss.value.push({ name });
}

async function handleSubmit() {
  showMessage.value = false;
  const data = await createLocationCode(
    locationChoosen.value,
    "location_admin"
  );

  sendRegistrationEmail(data.data.location_code);
}
async function sendRegistrationEmail(code) {
  showMessage.value = false;
  try {
    await registrationEmail({ email: email.value, code });

    message.value = "The email has been sent successfully";
    showMessage.value = true;
  } catch (error) {
    showMessage.value = true;
  }
}
</script>
